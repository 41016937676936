import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Text } from 'theme-ui'

const PageCookiePolicy = props => {
  return (
    <Layout {...props}>
      <Seo title='Cookie Policy' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Cookie Policy'
          subheader='Pinout'
        />
      </Stack>
      <Stack>
        <Main>
        <Text variant='h3'>Cookie Policy</Text>
        <Text variant='p'>Pinout uses cookies on our website to improve the user experience and to understand how our website is being used. By continuing to use this website, you consent to our use of cookies.</Text>
        <Text variant='h3'>What are cookies?</Text>
        <Text variant='p'>Cookies are small text files that are placed on your computer or mobile device by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. They can also be used to serve targeted ads.</Text>
        <Text variant='h3'>Third Party Cookies</Text>
        <Text variant='p'>Pinout uses third party providers such as Google and Facebook to serve targeted ads on our website. These providers may use cookies to collect information about your visit to our website, including your browsing history and your interaction with our products and services. This information is used to serve ads that are more relevant to you. No personally identifiable information is collected or used in this process.</Text>
        <Text variant='h3'>Disabling Cookies</Text>
        <Text variant='p'>You can disable cookies on your browser at any time. However, please note that disabling cookies may affect your ability to use certain features of our website.</Text>
        <Text variant='h3'>Changes to our Cookie Policy</Text>
        <Text variant='p'>Pinout reserves the right to make changes to this Cookie Policy at any time and for any reason. We will notify you of any changes to our Cookie Policy by posting the new Cookie Policy on this page. Your continued use of the website following the posting of changes to this policy will mean you accept those changes.</Text>
        <Text variant='p'>Thank you for using Pinout!</Text>
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageCookiePolicy
